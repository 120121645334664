<template>
 <div class="role-table">
    <avue-crud
        v-bind="bindVal"
        v-on="onEvent"
        v-model="form"
        :page.sync="page">
        <template slot="menuLeft"
            slot-scope="{ size }">
            <el-button
              :size="size"
              type="success"
              icon="el-icon-edit"
              @click="onResetPwd"
              >重置密码</el-button
            >
          </template>
        <template #btn="{row}" >
        <div class="btn_class">
          <el-button
            type="text"
             icon="el-icon-edit"
            @click="onFucAdmin(row, 1)"
            >管理角色</el-button
          >
        </div>
      </template>
    </avue-crud>
    <basic-dialog
        ref="dialog"
        title=""
        :isMaskClose="true"
        width="60%"
      >
      <user-role v-if="userId !== undefined" :userId="userId"></user-role>
    </basic-dialog>
    <el-dialog
      title="重置密码"
      :visible.sync="dialogVisible"
      width="40%"
      :modal="false"
      :destroy-on-close="true"
      >
      <avue-form :option="options" v-model="obj" @submit="submit" @reset-change="handleReset" @error="error"></avue-form>
    </el-dialog>
    <!-- <basic-dialog
        ref="resetPasswordDialog"
        title="重置密码"
        :isMaskClose="true"
        width="40%"
      >
    </basic-dialog> -->
 </div>
</template>

<script>
import userRole from "../basic-util-admin/user-role.vue"
import {ajax as $ajax} from "@/utils/libs/utils.http.js";
let crud = window.$crudCommon({}, {
  name: "user/index",
  pageNumber: 'current',//页码
  pageSize: 'size',//页数
  total: 'total',//总页数
  res:(data)=>{
    return {
      total: data.total,
      data: data.records
    }
  },//列表的结构
})
export default {
  name: '',
  components: {
    userRole,
  },
  mixins: [crud],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
  },
  data () {
    var regfix = (rule, value, callback)=>  {
        let reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,20}$/
        if (value === '') {
            callback(new Error('请输入重置密码'));
        } else if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('“密码必须包含字母、数字、特殊字符，且长度为8-20位”'));
        }
    };
    return {
      userId: undefined,
      dialogVisible: false,
      obj: {
        passwd: ''
      },
      options: {
        column:[
             {
              label:'密码',
              type:'password',
              showPassword: true,
              prop:'passwd',
              rules: [{
                required: true,
                validator: regfix,
                trigger: "blur"
              }]
            }
          ]
      },
      selectionId: ''
    }
  },
  watch: {
    // options: {
    //   handler (options) {
    //     // 设置true清空echart缓存
    //     this.chart.setOption(options, true)
    //   },
    //   deep: true
    // }
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    // 按钮操作
    onFucAdmin(row) {
      this.userId = undefined
      /**
       * @type
       * 1 : 具备角色
       */
      setTimeout(() => {
        this.userId = row.id
        this.$refs.dialog.show('具备角色')
      }, 300)
    },
    onResetPwd() {
      let selection = this.selectionData
      console.log('', selection);
      if (selection && selection.length > 0 && selection.length <= 1) {
        this.selectionId = selection[0].id
        this.dialogVisible = true
        this.obj = {}
        // this.$refs.resetPasswordDialog.show('重置密码')
      } else {
         this.$message.error('请选择，并且只能选择一个');
      }
    },
    submit(form,done) {
      $ajax({
          type: "put",
          dataType: "json",
          url : '/user/user-core/reset/password',
          data: {
            passwd: form.passwd,
            userId: this.selectionId,
          }
      }).then(res => {
          if (res.code === 200) {
            done()
            // this.$refs.resetPasswordDialog.hide()
            this.dialogVisible = false
            this.$message.success('重置成功');
            this.obj = {}
            this.onEvent['refresh-change']()
          } else {
            this.$message(res.errMsg)
          }
      })
    },
    error(err) {
    },
    handleReset() {
    }
  }
}
</script>

<style>
  .role-table {
    /* padding: 0 25px 0 25px; */
    color: white !important;;
  }
  .avue-group{
    padding-top: 10px;
  }
  .avue-crud__menu {
    padding: 10px;
  }
  .el-card__body {
    background: transparent !important;
  }
  /* .el-table, .el-table__expanded-cell {
    background: #0f1325 !important;
  } */
  .el-table--border, .el-table--group {
    border:  none !important;
  }
  .el-button--primary {
    background-color: #e94e46;
    border-color: #e94e46;
  }
  .el-button--primary:focus, .el-button--primary:hover {
    background-color: #e94e46;
    border-color: #e94e46;
    opacity: 0.7;
  }
  .el-button--text {
    color: #e94e46;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #e94e46;
  }
  /* .el-table tr{
      margin: 0;
      padding: 0;
      height: 50px;
  }
  .el-table thead tr{
      background: rgba(5, 58, 152, 0.5);
  }
  .el-table tbody .row-class-fix td:first-child{
      border-left: 1px solid #053A98;
  }
  .el-table td{
      border-bottom: 1px solid #053A98;
      border-right: 1px solid #053A98;
  } */
</style>
